.CheckboxOutline {
	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		display: block;
		position: relative;
		cursor: pointer;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.5px;
		color: #242f57;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: -12px;
		left: 0;
		height: 24px;
		width: 24px;
		background: #ffffff;
		border: 2px solid #d2d5e1;
		box-sizing: border-box;
		border-radius: 8px;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background: rgba(54, 154, 254, 0.1);
		border: 2px solid #d2d5e1 !important;
	}

	/* When the checkbox is checked, add a light green background */
	.container input:checked ~ .checkmark {
		background-color: transparent;
		border: 2px solid #369afe !important;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid #369afe;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
