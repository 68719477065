.LightWrapper {
	width: 100%;
	margin-top: 25px;
	background: #ffffff;
	border: 1px solid #eaedf7;
	box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
	border-radius: 10px;

	.division {
		padding: 24px;
	}

	.hr {
		background: #eaedf7;
		height: 1px;
		box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
	}
}
