.App {
	max-width: 100vw;
	position: relative;

	& > .layout {
		width: 100%;

		display: flex;

		& > .side_nav {
			width: 233px;
		}

		& > .display {
			width: calc(100% - 233px);
			min-height: 100vh;
			background: #f4f7fc;
		}
	}
}
