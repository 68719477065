.LeaderBoard {
	width: 100%;

	.title {
		display: flex;
		align-items: center;

		h1 {
			font-style: normal;
			font-weight: bold;
			font-size: 26px;
			line-height: 36px;
			letter-spacing: 0.5px;
			color: #242f57;
			margin-right: 9px;
		}

		.refresh {
			display: flex;
			align-items: center;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #369afe;
			cursor: pointer;

			svg {
				margin-right: 4.5px;
			}

			.rotate {
				animation: rotate 600ms ease-in infinite;
			}
		}
	}

	.last_updated {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.5px;
		color: #636e95;

		span {
			color: #369afe;
		}
	}

	.pill {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #eaedf7;
		box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);

		.division {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.left {
			display: flex;
			align-items: center;

			.photo {
				width: 56px;
				height: 56px;
				margin-right: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #242f57;
				border-radius: 50%;

				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #ffffff;
				text-transform: uppercase;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.details {
				.name {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 0.5px;
					color: #242f57;
					text-transform: capitalize;
				}

				.phone,
				.location {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.5px;
					color: rgba(99, 110, 149, 0.9);
				}
			}
		}

		.right {
			display: flex;
			align-items: center;

			.buttons {
				display: flex;
				column-gap: 7px;

				& > div {
					width: 139px;

					.OutlineButton {
						margin: 0;
					}
				}
			}

			svg {
				margin-left: 27px;
				cursor: pointer;
			}
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
