.Dashboard {
	width: 100%;

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 36px;
		letter-spacing: 0.5px;
		color: #242f57;
	}

	.date {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.5px;
		position: relative;

		color: #636e95;

		span {
			color: #369afe;
			cursor: pointer;
		}

		.date_picker {
			position: absolute;
			bottom: -425px;
			z-index: 9999999;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
		}
	}

	.report {
		display: flex;
		justify-content: space-between;

		h3 {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 30px;
			letter-spacing: 0.5px;
			color: #242f57;
		}

		p {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.5px;
			color: #636e95;
		}
		.customSelect {
			width: 200px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 12px 10px;
			background: #fafcfe;
			border: 1px solid #d2d5e1;
			border-radius: 10px;
			cursor: pointer;
			position: relative;

			& > .group {
				display: flex;
				align-items: center;
			}

			.color {
				width: 15px;
				height: 15px;
				border-radius: 4px;
			}

			.text {
				margin-left: 6px;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			svg {
				margin-left: 6px;
			}

			.dropdown {
				position: absolute;
				bottom: -100px;
				left: 0;
				right: 0;
				background: #ffffff;
				border: 1px solid rgba(216, 216, 216, 0.3);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
				border-radius: 5px;
				padding: 8px 0;

				& > div {
					display: flex;
					align-items: center;
					padding: 10px 14px;

					&:hover {
						background: #f4faff;
					}

					// &:first-child {
					// 	padding-top: 0;
					// }

					// &:last-child {
					// 	padding-bottom: 0;
					// }
				}
			}
		}
	}

	.values {
		display: flex;
		gap: 42.5px;

		& > div {
			.title {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.5px;
				color: #97a0c3;
			}

			.value {
				display: flex;

				.no {
					font-style: normal;
					font-weight: bold;
					font-size: 22px;
					line-height: 28px;
					letter-spacing: 0.5px;
					color: #242f57;
				}

				.percentage {
					margin-left: 6.5px;

					&.new_registration {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.5px;
						color: #04b78a;
					}

					&.donations {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.5px;
						color: #fa5087;
					}
				}
			}
		}
	}

	.header {
		h3 {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 30px;
			/* identical to box height, or 136% */

			letter-spacing: 0.5px;

			color: #242f57;
		}

		.chart {
			width: 100%;
			height: 14px;
			margin-top: 24.5px;
			background: #eaedf7;
			border-radius: 4px;

			.track {
				width: 65%;
				height: 100%;
				background: #9390f3;
				border-radius: 4px;
			}
		}
	}

	._values {
		display: flex;
		justify-content: space-between;

		.title {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 26px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #97a0c3;
		}

		.value {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 28px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #242f57;
		}
	}

	.canvassing_report_chart {
		width: 100%;
		height: 430px;
	}
}
