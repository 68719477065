.Button {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 32px 0 0 0;

	input,
	button {
		border: none;
		background-color: transparent;
		outline: none;

		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		letter-spacing: 0.5px;

		color: #ffffff;
		padding: 9px;
		background: #369afe;
		border-radius: 8px;
		cursor: pointer;

		& > div[data-testid] {
			display: block !important;
		}

		&.disabled {
			background: rgba(2, 199, 150, 0.2);
			cursor: not-allowed;
		}
	}
}
