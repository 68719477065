.ResetPassword {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& > main {
		width: 567px;

		& > h1 {
			text-align: center;
			font-style: normal;
			font-weight: bold;
			font-size: 26px;
			line-height: 36px;
			letter-spacing: 0.5px;
			color: #242f57;
			margin-bottom: 24px;
		}

		& > p {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #242f57;
		}

		& > form {
			.email {
				width: 419px;
				margin: 40px auto 0 auto;
			}

			.button {
				width: 172px;
				margin: 0 auto;
			}
		}

		.need_help {
			display: block;
			margin: 69px auto 0 auto;
			text-align: center;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #242f57;

			a {
				color: #369afe;
			}
		}
	}
}
