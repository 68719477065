*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Inter', sans-serif;
	// &::-webkit-scrollbar-track {
	// 	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	// 	background-color: #f5f5f5;
	// }

	.Input,
	input {
		/* Change the white to any color */
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
			box-shadow: 0 0 0 30px white inset !important;
		}
	}

	// &::-webkit-scrollbar {
	// 	width: 7px;
	// 	background-color: #f5f5f5;
	// }

	// &::-webkit-scrollbar-thumb {
	// 	// border-radius: 4px;
	// 	background-color: #0a302b;
	// 	border: 2px solid transparent;
	// }

	a {
		text-decoration: none;
	}
}
