.SideNav {
	width: 233px;
	height: 100vh;
	z-index: 999;

	position: fixed;
	left: 0;
	top: 0;

	display: flex;
	flex-direction: column;
	// justify-content: center;
	background: #0080ff;

	.logo {
		width: 100%;
		height: 82px;
		display: flex;
		justify-content: center;
		align-items: center;

		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}

	.nav_links {
		height: calc(100vh - 82px);
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		overflow: auto;

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			background-color: #f5f5f5;
		}
		&::-webkit-scrollbar {
			width: 7px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #fff;
			border: 2px solid transparent;
		}

		section {
			.section_title {
				margin: 35px 0 13px 21px;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 2px;
				color: rgba(255, 255, 255, 0.9);
			}
			& > a {
				width: 218px;
				height: 59px;
				border-radius: 0px 10px 10px 0px;

				display: flex;
				align-items: center;
				border-left: 2.5px solid transparent;
				transition: all 150ms cubic-bezier(1, 0, 0, 1);

				&.active {
					background-color: rgba(255, 255, 255, 0.16);
					border-left: 2.5px solid rgba(255, 255, 255, 0.8);

					.icon {
						svg {
							stroke: #fff !important;
							fill: #fff !important;
						}
					}

					.title {
						font-weight: 500;
					}
				}

				.title {
					font-style: normal;
					font-weight: 300;
					font-size: 17px;
					line-height: 22px;
					letter-spacing: 0.5px;
					color: #ffffff;
				}

				.icon {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 12.6px;
					margin-left: 21px;

					svg {
						width: 100%;
						height: 100%;
						stroke: #ffffff;
						transition: all 150ms cubic-bezier(1, 0, 0, 1);
					}
				}

				&:hover svg {
					stroke: #fff !important;
					fill: #fff !important;
				}

				&:hover {
					background-color: rgba(255, 255, 255, 0.16);

					.title {
						font-weight: 500;
					}
				}
			}
		}
	}
}
