.Post {
	width: 100%;

	.LightWrapper {
		width: 100%;
		margin-top: 0;
		margin-bottom: 25px;
		background: #ffffff;
		border: 1px solid #eaedf7;
		box-shadow: 0px 2px 4px rgb(28 41 90 / 4%);
		border-radius: 10px;
	}

	.head {
		width: 100%;
		display: flex;
		justify-content: space-between;

		& > div {
			display: flex;
			align-items: center;
		}

		.author {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #242f57;
		}

		.time {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(151, 160, 195, 0.9);
		}

		.photo {
			width: 42px;
			height: 42px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #242f57;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tag {
			background: #e7e6fe;
			border-radius: 8px;
			padding: 8px 23px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #4540bb;
		}

		.edit_delete {
			display: flex;
			align-items: center;
			column-gap: 11px;

			& > div {
				width: 147px;

				& > .Button {
					margin: 0;
				}
			}
		}
	}

	.featured_image {
		margin-top: 16px;
		width: 100%;

		img {
			width: 100%;
			border-radius: 10px;
		}
	}

	.title {
		margin-top: 16px;
		font-size: 24px;
		line-height: 24px;
		letter-spacing: 0.857143px;
		color: #242f57;
	}

	.reactions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div.react {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 17px;
		}

		.likes {
			display: flex;
			align-items: center;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(0, 128, 255, 0.9);

			div.count {
				margin-right: 8px;
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.5px;
				color: #ffffff;
				background: #369afe;
				border-radius: 6px;
			}
		}

		.comments,
		.shares {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(99, 110, 149, 0.9);
		}

		.options {
			cursor: pointer;
		}
	}
	.text {
		margin-top: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.5px;
		color: #636e95;

		& > span {
			color: #369afe;
			cursor: pointer;
		}
	}

	.create_post_btn {
		.Button {
			margin: 0;
		}
	}

	.groups {
		width: 100%;

		.head {
			display: flex;
			justify-content: space-between;
			align-items: center;

			._groups {
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				line-height: 30px;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			.see_all {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				cursor: pointer;
				color: #369afe;
			}
		}

		.group_list {
			margin-top: 35px;
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			.group {
				display: flex;
				align-items: center;

				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				letter-spacing: 0.666667px;
				color: rgba(36, 47, 87, 0.9);

				.cover {
					margin-right: 8px;
					width: 32px;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #ff0a5a;
					border-radius: 50%;

					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					letter-spacing: 0.666667px;
					color: rgba(255, 255, 255, 0.9);
				}
			}
		}
	}

	.categories {
		width: 100%;
		display: flex;
		gap: 14px;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 16px;

		.pills {
			background: #ffffff;
			border: 1px solid #eaedf7;
			border-radius: 8px;
			padding: 9px 23px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #369afe;
			cursor: pointer;

			&.active {
				background: #369afe;
				color: #ffffff;
			}
		}
	}
}
