.SelectInput {
	display: flex;
	align-items: center;
	position: relative;
	// margin-bottom: 24px;

	& > .select {
		width: 176px;
		height: 43px;
		display: flex;
		justify-content: space-between;
		padding: 15px;
		align-items: center;

		background: #ffffff;
		border: 1px solid #eef0f9;
		box-shadow: 0px 4px 10px 10px rgba(230, 234, 248, 0.08);
		border-radius: 8px;
		cursor: pointer;

		span.option {
			margin-right: 16px;

			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #2a206a;
		}
	}

	& > svg {
		cursor: pointer;
	}

	.dropdown {
		position: absolute;
		top: 45px;
		left: 0;
		right: 0;
		background: #ffffff;
		border: 1px solid rgba(216, 216, 216, 0.3);
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
		border-radius: 5px;
		padding: 8px 0px;
		z-index: 5;
		max-height: 200px;
		overflow-y: auto;

		& > div {
			display: flex;
			align-items: center;
			padding: 8px 14px;
			cursor: pointer;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #2a206a;

			&:hover {
				background: #f4faff;
			}

			// &:first-child {
			// 	padding-top: 0;
			// }

			// &:last-child {
			// 	padding-bottom: 0;
			// }
		}
	}
}
