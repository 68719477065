.Volunteers {
	width: 100%;

	.title {
		display: flex;
		align-items: center;
		div {
			h1 {
				font-style: normal;
				font-weight: bold;
				font-size: 26px;
				line-height: 36px;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			.search_queries {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.5px;
				color: #636e95;

				span {
					color: #369afe;
					cursor: pointer;
				}
			}
		}

		a {
			width: 208px;
			.Button {
				margin-top: 0;
				margin-left: 25px;
			}
		}
	}

	.search_by {
		margin-top: 30px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		row-gap: 24px;

		& > ._select {
			& > span {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 25px;
				color: #242f57;
			}

			display: flex;
			column-gap: 24px;
			align-items: center;
		}

		._btn {
			width: 208px;

			& > .Button {
				margin: 0;
			}
		}
	}

	.profile_cards {
		width: 100%;
	}

	.pagination {
		margin-top: 30px;
		width: 100%;

		display: flex;
		justify-content: flex-end;

		.pagination_container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;

			button.dest {
				width: 40px;
				height: 40px;

				border: 1px solid #cdd1de;
				box-sizing: border-box;
				border-radius: 4px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				color: #868e96;
				background-color: transparent;
				outline: none;
				transition: all 200ms ease;

				& > svg {
					stroke: #c8cad8;
				}

				&.active,
				&:hover {
					background-color: #369afe;
					color: #ffffff;

					svg {
						stroke: #ffffff;
					}
				}

				&.disabled {
					cursor: not-allowed;
					&:hover {
						background-color: transparent;

						svg {
							stroke: #c8cad8;
						}
					}
				}
			}
		}
	}
}
