.Input {
	width: 100%;
	display: flex;
	flex-direction: column;
	// margin: 16px 0 0 0;

	label {
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 2px;
		color: rgba(151, 160, 195, 0.9);
	}

	input,
	textarea {
		border: none;
		background-color: transparent;
		outline: none;
		margin: 19px 0;

		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.5px;
		color: #636e95;

		padding: 12px;
		background: #fafcfe;
		border: 1px solid #97a0c3;
		border-radius: 10px;
	}
}

.Input2 {
	width: 176px;
	height: 43px;
	display: flex;
	flex-direction: column;

	input {
		width: 176px;
		height: 43px;
		border: none;
		background-color: transparent;
		outline: none;

		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #2a206a;

		padding: 15px;
		background: #ffffff;
		border: 1px solid #eef0f9;
		box-shadow: 0px 4px 10px 10px rgba(230, 234, 248, 0.08);
		border-radius: 8px;
	}
}
