.Donations {
	width: 100%;

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 36px;
		letter-spacing: 0.5px;
		color: #242f57;
	}

	.no-donations {
		align-items: center;
		img {
			width: 40px;
			margin-right: 5px;
		}
		h2 {
			font-style: normal;
			font-weight: bold;
			font-size: 26px;
			line-height: 36px;
			letter-spacing: 0.5px;
			color: #242f57;
			margin-bottom: 0;
		}
	}

	.query {
		width: 100%;
		display: flex;
		align-items: center;

		.dropdown {
			width: 247px;
			background: #fafcfe;
			border: 1px solid #d2d5e1;
			border-radius: 10px 0px 0px 10px;
			padding: 12px;
			height: 48px;

			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			span {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.5px;
				color: #97a0c3;
			}
		}

		.search {
			width: calc(100% - 247px);
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #fafcfe;
			border: 1px solid #d2d5e1;
			border-left: none;
			border-radius: 0px 10px 10px 0px;
			padding: 12px;
			height: 48px;

			input {
				width: 100%;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.5px;
				color: #97a0c3;

				border: none;
				outline: none;
				background-color: transparent;
			}
		}
	}
	.isLoading {
		width: 100%;
		height: 450px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.Table {
		margin: 32px 0;
		margin-bottom: 25px;
		overflow: auto;

		table {
			width: 100%;
			min-width: 1152px;

			border: none;
			thead {
				padding: 20px 40px;
				border-radius: 8px 8px 0px 0px;
				tr {
					width: 100%;
					th {
						border-left: 3px solid transparent;
						padding: 20px 0px 20px 20px;

						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						line-height: 15px;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: rgba(151, 160, 195, 0.9);

						&:first-child {
							border-radius: 8px 0px 0px 0px;
							padding: 20px 0px 20px 25px;
						}
						&:last-child {
							border-radius: 0px 8px 0px 0px;
							padding-right: 10px;
						}
					}
				}
			}

			tbody {
				tr {
					transition: 30ms ease;
					border-left: 3px solid transparent;
					&:hover {
						background: #f4faff;
						border-left: 3px solid #369afe;
					}

					td {
						padding: 16px 20px;

						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 24px;
						letter-spacing: 0.5px;
						color: #242f57;

						&.order_id {
							color: #636e95;
						}

						&.customer {
							color: #0080ff;
							display: flex;
							align-items: center;

							& > div {
								width: 28px;
								height: 28px;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 50%;
								background: #6863fb;
								font-style: normal;
								font-weight: 500;
								font-size: 12px;
								line-height: 15px;
								color: #ffffff;
								margin-right: 12px;
							}
						}
						&.status {
							span {
								padding: 6px 18px;
								border-radius: 8px;
								text-align: center;

								&.success {
									color: #1fd0a3;
									background: #dbf7f0;
								}

								&.pending {
									background: #fff6d6;
									color: #ffc800;
								}

								&.failed {
									background: #fee3ec;
									color: #fa5087;
								}
							}
						}

						&.options {
							cursor: pointer;
						}

						&:first-child {
							border-radius: 8px 0px 0px 0px;
							padding: 16px 25px;
						}
					}
				}
			}
		}
	}

	.pagination {
		margin-top: 30px;
		margin-bottom: 30px;
		width: 100%;

		display: flex;
		justify-content: flex-end;

		.pagination_container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;

			button.dest {
				width: 40px;
				height: 40px;

				border: 1px solid #cdd1de;
				box-sizing: border-box;
				border-radius: 4px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				color: #868e96;
				background-color: transparent;
				outline: none;
				transition: all 200ms ease;

				& > svg {
					stroke: #c8cad8;
				}

				&.active,
				&:hover {
					background-color: #369afe;
					color: #ffffff;

					svg {
						stroke: #ffffff;
					}
				}

				&.disabled {
					cursor: not-allowed;
					&:hover {
						background-color: transparent;

						svg {
							stroke: #c8cad8;
						}
					}
				}
			}
		}
	}
}
