.Login {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& > main {
		width: 296px;

		& > h1 {
			text-align: center;
			font-style: normal;
			font-weight: bold;
			font-size: 26px;
			line-height: 36px;
			letter-spacing: 0.5px;
			color: #242f57;
			margin-bottom: 32px;
		}

		.reset_password {
			display: block;
			margin: 28px auto 0 auto;
			text-align: center;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			/* identical to box height, or 171% */

			letter-spacing: 0.5px;

			color: #369afe;
		}
	}
}
