.SpectrumReports {
	width: 100%;

	.LightWrapper {
		width: 100%;
		margin-top: 0;
		margin-bottom: 25px;
		background: #ffffff;
		border: 1px solid #eaedf7;
		box-shadow: 0px 2px 4px rgb(28 41 90 / 4%);
		border-radius: 10px;
	}

	.head {
		width: 100%;
		display: flex;
		justify-content: space-between;

		& > div {
			display: flex;
			align-items: center;
		}

		.author {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #242f57;
		}

		.time {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(151, 160, 195, 0.9);
		}

		.photo {
			width: 42px;
			height: 42px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #242f57;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tag {
			background: #e7e6fe;
			border-radius: 8px;
			padding: 8px 23px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #4540bb;
		}

		.option {
			position: relative;
			cursor: pointer;
			svg {
				margin-left: 18px;
			}

			.dropdown {
				position: absolute;
				width: 272px;
				top: 40px;
				left: -30px;
				right: 0;
				background: #ffffff;
				border: 1px solid rgba(216, 216, 216, 0.3);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
				border-radius: 5px;
				padding: 14px 0;

				& > div {
					margin-top: 0 !important;
					display: flex;
					align-items: center;
					padding: 5px 14px;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.5px;
					color: #242f57;

					&:hover {
						background: #f4faff;
					}
				}
			}
		}
	}

	.featured_image {
		margin-top: 16px;
		width: 100%;

		img {
			width: 100%;
			border-radius: 10px;
		}
	}

	.title {
		margin-top: 16px;
		font-size: 24px;
		line-height: 24px;
		letter-spacing: 0.857143px;
		color: #242f57;
	}

	.reactions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div.react {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 17px;
		}

		.likes {
			display: flex;
			align-items: center;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(0, 128, 255, 0.9);

			div.count {
				margin-right: 8px;
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.5px;
				color: #ffffff;
				background: #369afe;
				border-radius: 6px;
			}
		}

		.comments,
		.shares {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(99, 110, 149, 0.9);
		}

		.options {
			cursor: pointer;
		}
	}
	.text {
		margin-top: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.5px;
		color: #636e95;

		& > span {
			color: #369afe;
			cursor: pointer;
		}
	}

	.create_post_btn {
		.Button {
			margin: 0;
		}
	}

	.groups {
		width: 100%;

		.head {
			display: flex;
			justify-content: space-between;
			align-items: center;

			._groups {
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				line-height: 30px;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			.see_all {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				cursor: pointer;
				color: #369afe;
			}
		}

		.group_list {
			margin-top: 35px;
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			.group {
				display: flex;
				align-items: center;

				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				letter-spacing: 0.666667px;
				color: rgba(36, 47, 87, 0.9);

				.cover {
					margin-right: 8px;
					width: 32px;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #ff0a5a;
					border-radius: 50%;

					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					letter-spacing: 0.666667px;
					color: rgba(255, 255, 255, 0.9);
				}
			}
		}
	}

	.categories {
		width: 100%;
		display: flex;
		gap: 14px;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 16px;

		.pills {
			background: #ffffff;
			border: 1px solid #eaedf7;
			border-radius: 8px;
			padding: 9px 23px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #369afe;
			cursor: pointer;

			&.active {
				background: #369afe;
				color: #ffffff;
			}
		}
	}
}

.pagination {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	.page {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.5px;

		color: #222f5a;
	}

	.pagination_container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		button.dest {
			border: none;
			padding: 3px;
			outline: none;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #222f5a;
			background-color: transparent;
			outline: none;
			transition: all 200ms ease;

			& > svg {
				stroke: #1f2f5d;
			}

			&.active,
			&:hover {
				color: #369afe;

				svg {
					stroke: #369afe;
				}
			}

			&.disabled {
				cursor: not-allowed;

				svg {
					stroke: #d8d8d8;
				}
				&:hover {
					background-color: transparent;

					svg {
						stroke: #d8d8d8;
					}
				}
			}
		}
	}
}

.CreatePost {
	width: 100%;

	.LightWrapper {
		width: 100%;
		margin-top: 0;
		margin-bottom: 25px;
		background: #ffffff;
		border: 1px solid #eaedf7;
		box-shadow: 0px 2px 4px rgb(28 41 90 / 4%);
		border-radius: 10px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 23px;

		.lan {
			display: flex;
			align-items: center;

			.flag {
				width: 28px;
				height: 28px;
				margin-right: 15px;

				img {
					width: 100%;
					height: 100%;
				}

				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 29px;
				color: #4e5d78;
			}
		}

		.status {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 153px;
			height: 43px;
			padding: 14px;
			background: #fafbfc;
			border-radius: 15px;
			cursor: pointer;
			position: relative;

			.dropdown {
				position: absolute;
				bottom: -93px;
				left: 0;
				right: 0;
				background: #ffffff;
				border: 1px solid rgba(216, 216, 216, 0.3);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
				border-radius: 5px;
				padding: 9.5px 0;

				& > div {
					display: flex;
					align-items: center;
					padding: 5px 14px;

					&:hover {
						background: #f4faff;
					}

					// &:first-child {
					// 	padding-top: 0;
					// }

					// &:last-child {
					// 	padding-bottom: 0;
					// }
				}
			}

			.dot {
				width: 8px;
				height: 8px;
				background: #38cb89;
				border-radius: 50%;
				margin-right: 9px;
			}

			svg {
				margin-left: 25px;
			}
		}
	}

	.editor {
		// height: 600px;
		.title_img {
			display: flex;
			align-items: center;
			margin-bottom: 36px;

			.photo {
				width: 72px;
				height: 72px;
				background: #f4f8ff;
				border-radius: 50%;
				margin-right: 28px;
			}

			.title {
				input {
					border: none;
					outline: none;
					width: 500px;
					font-style: normal;
					font-weight: 600;
					font-size: 22px;
					line-height: 29px;
					/* or 132% */

					display: flex;
					align-items: center;

					/* Text 2 */

					color: #4e5d78;
				}
			}
		}

		.toolbar-class {
			background: #fafbfc;
			border-radius: 15px;
			padding: 22px;
		}

		.editor-class {
			margin-top: 39px;
			color: #636e95;
			// padding: 0 22px;
			// border: 1px solid #7e7e7e;
			// background: #fafbfc;
			// border-radius: 15px;
		}
	}

	.section {
		width: 100%;
		margin-bottom: 27px;
		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 29px;
			color: #323b4b;
		}

		.uploaded_Image {
			width: 100%;
			border-radius: 15px;
			border: 1.5px dashed #f3f3f3;
			overflow: hidden;
			margin-bottom: 10px;

			img {
				width: 100%;
			}
		}

		span {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 29px;
			color: #b0b7c3;
			display: block;
		}

		.select {
			margin-top: 18px;
			height: 44px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #fafbfc;
			border-radius: 15px;
			padding: 16px;

			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 29px;
			color: #4e5d78;
			cursor: pointer;

			position: relative;

			.dropdown {
				position: absolute;
				top: 48px;
				left: 0;
				right: 0;
				background: #ffffff;
				border: 1px solid rgba(216, 216, 216, 0.3);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
				border-radius: 5px;
				padding: 8px 0px;
				z-index: 10;
				max-height: 200px;
				overflow-y: auto;

				& > div {
					display: flex;
					align-items: center;
					padding: 4px 14px;

					&:hover {
						background: #f4faff;
					}

					// &:first-child {
					// 	padding-top: 0;
					// }

					// &:last-child {
					// 	padding-bottom: 0;
					// }
				}
			}

			input {
				border: none;
				outline: none;
				width: 100%;
				background-color: transparent;
			}
		}

		label {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			text-align: center;
			color: #369afe;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;

			.previewImage {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			p {
				margin-top: 10px;
			}

			input {
				height: 0;
				width: 0;
				opacity: 0;
			}

			background: #fafbfc;
			border-radius: 15px;
			border: 1.5px dashed #f3f3f3;
			border-radius: 15px;
			padding: 29px 0;
			div {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #b0b7c3;
				z-index: 10222;

				svg {
					margin-bottom: 20px;
				}
			}
		}

		.buttons {
			margin-top: 35px;
			display: flex;
			align-items: center;
			gap: 12px;
			button.cancel {
				border: none;
				outline: none;
				background-color: transparent;
				width: 50%;
				height: 44px;
				background: #fafbfc;
				border-radius: 15px;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 29px;
				color: #b0b7c3;
			}
			button.save {
				display: flex;
				justify-content: center;
				align-items: center;
				border: none;
				outline: none;
				background-color: transparent;
				width: 50%;
				height: 44px;
				background: #369afe;
				border-radius: 8px;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 29px;
				text-align: center;
				color: #ffffff;

				&.disabled {
					cursor: not-allowed;
					background: #a2c7ec;
				}
			}
		}
	}
}
