.Volunteer {
	width: 100%;

	.gray {
		width: 100%;
		height: 126px;
		background: rgba(0, 0, 0, 0.02);
		box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
		border-radius: 10px 10px 0px 0px;

		.leadership_head {
			display: block;
			margin-top: 31px;
			text-align: right;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #242f57;
		}
	}

	.vol_details {
		display: flex;
		align-items: center;
		margin-top: -40px;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: -6.5px;
			left: calc(28px + 94px);
			width: 87.5%;
			height: 1.5px;
			background-color: #eaedf7;
			box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
		}

		.photo {
			width: 94px;
			height: 94px;
			margin-right: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #242f57;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.right {
			display: flex;
			margin-top: 18px;
			.name_no {
				// margin-right: 18px;
				margin-right: 294px;

				.name {
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					line-height: 24px;
					/* identical to box height, or 133% */

					letter-spacing: 0.5px;

					color: #242f57;
					margin-bottom: 6px;
				}

				.vol {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.5px;
					color: #636e95;
				}
			}

			.verified {
				height: fit-content;
				display: flex;
				align-items: center;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.5px;
				color: #242f57;
				margin-right: 186px;

				svg {
					margin-right: 6px;
				}
			}

			.__right {
				&.__next {
					margin-right: 32px;
				}
				.up {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 0.5px;
					color: #242f57;
					margin-bottom: 3px;
				}

				.down {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.5px;
					color: rgba(99, 110, 149, 0.9);
				}
			}
		}
	}

	.LightWrapper {
		background: #ffffff;
		// border: none;
		// box-shadow: none;
	}

	.pagination {
		margin-top: 30px;
		margin-bottom: 30px;
		width: 100%;

		display: flex;
		justify-content: flex-end;

		.pagination_container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;

			button.dest {
				width: 40px;
				height: 40px;

				border: 1px solid #cdd1de;
				box-sizing: border-box;
				border-radius: 4px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				color: #868e96;
				background-color: transparent;
				outline: none;
				transition: all 200ms ease;

				& > svg {
					stroke: #c8cad8;
				}

				&.active,
				&:hover {
					background-color: #369afe;
					color: #ffffff;

					svg {
						stroke: #ffffff;
					}
				}

				&.disabled {
					cursor: not-allowed;
					&:hover {
						background-color: transparent;

						svg {
							stroke: #c8cad8;
						}
					}
				}
			}
		}
	}
}
