.Settings {
	width: 100%;

	.LightWrapper {
		margin-top: 0;
		margin-bottom: 25px;
	}

	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > div {
			.change_password {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 26px;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			.text {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.5px;
				color: rgba(99, 110, 149, 0.9);
			}
		}

		.update_password {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 22px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #369afe;
		}
	}

	form {
		margin-top: 48px;

		.button {
			display: flex;
			justify-content: flex-end;
			& > div {
				width: 239px;

				.Button {
					margin: 0;
				}
			}
		}
	}
}
