.Wrapper {
	width: 100%;
	height: 246px;
	margin-top: 25px;
	background: #ffffff;
	border: 1px solid #eaedf7;
	box-shadow: 0px 2px 4px rgb(28 41 90 / 4%);
	border-radius: 10px;
	padding: 24px;
	position: relative;
	overflow: hidden;
}
.ProfileCardSkeleton {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.options {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	.photo {
		margin-top: 14px;
		margin-bottom: 18px;
		width: 56px;
		height: 56px;
		background-color: #f2f2f2;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.user {
		width: 100px;
		height: 20px;
		margin-top: 2px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.5px;
		color: #242f57;
		background-color: #f2f2f2;
	}

	.email {
		width: 150px;
		height: 13px;
		margin-top: 5px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.5px;
		color: rgba(99, 110, 149, 0.9);
		background-color: #f2f2f2;
	}

	.__btn {
		width: 126px;
		height: 38px;
		background-color: #f2f2f2;
		margin-top: 26px;
		border-radius: 8px;
	}
}

/* animation effects */
.shimmer-wrapper {
	position: absolute;
	/* parent must be relative position & overflow hidden */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: loading 1.5s ease-in-out infinite alternate-reverse;
}
.shimmer {
	width: 50%;
	height: 100%;
	background: rgba(255, 255, 255, 0.6);
	transform: skewX(-20deg);
	box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}
.dark .shimmer {
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
	0% {
		transform: translateX(-150%);
	}
	50% {
		transform: translateX(-60%);
	}
	100% {
		transform: translateX(150%);
	}
}

.UserSkeleton {
	width: 100%;
	position: relative;
	overflow: hidden;

	.h1 {
		width: 200px;
		height: 36px;
		background-color: #a0a0a034;
	}

	.h3 {
		margin-top: 30px;
		width: 160px;
		height: 30px;
		background-color: #e6e6e688;
	}

	span {
		display: block;
		margin-top: 10px;
		width: 360px;
		height: 16px;
		background-color: #e6e6e6b2;
	}

	.photo_details {
		display: flex;
		align-items: center;
		margin-top: 40px;

		.photo {
			width: 62px;
			height: 62px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e6e6e6b2;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			span {
				color: #ffffff;
			}

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.details {
			.name {
				display: block;
				margin-top: 10px;
				width: 160px;
				height: 16px;
				background-color: #e6e6e6b2;
			}

			.email {
				display: block;
				margin-top: 10px;
				width: 290px;
				height: 16px;
				background-color: #e6e6e6b2;
			}
		}
	}

	form {
		margin-top: 40px;
		width: 100%;
		height: 392px;
	}
	.label {
		display: block;
		margin-top: 10px;
		margin-bottom: 25px;
		width: 100px;
		height: 15px;
		background-color: #e6e6e6b2;
	}
	.input {
		display: block;
		margin-top: 10px;
		width: 100%;
		height: 45px;
		background-color: #e6e6e6b2;
	}
}

div.VolunteerSkeleton {
	width: 100%;
	position: relative;
	overflow: hidden;

	.gray {
		width: 100%;
		height: 126px;
		background: rgba(0, 0, 0, 0.02);
		box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
		border-radius: 10px 10px 0px 0px;

		.division {
			display: flex;
			justify-content: flex-end;
		}

		.leadership_head {
			width: 300px;
			height: 24px;
			background-color: #e6e6e6b2;
			display: block;
			margin-top: 31px;
			text-align: right;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #242f57;
		}
	}

	.vol_details {
		display: flex;
		align-items: center;
		margin-top: -40px;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: -6.5px;
			left: calc(28px + 94px);
			width: 87.5%;
			height: 1.5px;
			background-color: #eaedf7;
			box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
		}

		.photo {
			width: 94px;
			height: 94px;
			margin-right: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e6e6e6b2;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.right {
			display: flex;
			margin-top: 18px;
			.name_no {
				margin-right: 18px;
				.name {
					width: 260px;
					height: 20px;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					background-color: #e6e6e6b2;
					line-height: 24px;
					/* identical to box height, or 133% */

					letter-spacing: 0.5px;

					color: #242f57;
					margin-bottom: 6px;
				}

				.vol {
					width: 150px;
					height: 16px;
					font-style: normal;
					margin-top: 15px;
					font-weight: normal;
					background-color: #e6e6e6b2;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.5px;
					color: #636e95;
				}
			}

			.verified {
				height: fit-content;
				display: flex;
				align-items: center;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.5px;
				color: #242f57;
				margin-right: 186px;

				svg {
					margin-right: 6px;
				}
			}

			.__right {
				&.__next {
					margin-right: 32px;
				}
				.up {
					width: 50px;
					height: 20px;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					background-color: #e6e6e6b2;
					margin-bottom: 3px;
				}

				.down {
					margin-top: 15px;
					width: 90px;
					height: 13px;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					background-color: #e6e6e6b2;
				}
			}
		}
	}

	.LightWrapper {
		background: #ffffff;
		// border: none;
		// box-shadow: none;
	}
}

.LeaderBoardPillsSkeleton {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eaedf7;
	box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
	position: relative;
	overflow: hidden;

	.division {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.left {
		display: flex;
		align-items: center;

		.photo {
			width: 56px;
			height: 56px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e6e6e6b2;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.details {
			.name {
				width: 140px;
				height: 20px;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				background-color: #e6e6e6b2;
			}

			.phone,
			.location {
				width: 200px;
				height: 13px;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				background-color: #e6e6e6b2;
				margin: 6px 0;
			}
			.phone {
				width: 100px;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;

		.buttons {
			display: flex;
			column-gap: 7px;

			& > div {
				width: 139px;
				height: 38px;
				background-color: #e6e6e6b2;
				border-radius: 8px;
			}
		}

		svg {
			margin-left: 27px;
			cursor: pointer;
		}
	}
}

.DashboardSkeleton {
	width: 100%;
	position: relative;
	overflow: hidden;

	.h1 {
		width: 242px;
		height: 30px;
		background-color: #e6e6e6b2;
	}

	.date {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.5px;
		position: relative;

		color: #636e95;

		span {
			color: #369afe;
			cursor: pointer;
		}

		.date_picker {
			position: absolute;
			bottom: -425px;
			z-index: 9999999;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
		}
	}

	.report {
		display: flex;
		justify-content: space-between;

		h3 {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 30px;
			letter-spacing: 0.5px;
			color: #242f57;
		}

		p {
			background-color: #e6e6e6b2;
			width: 266px;
			height: 20px;
		}

		.right {
			width: 200px;
			height: 45px;
			background-color: #e6e6e6b2;
			display: block;
		}
		.customSelect {
			width: 200px;
			height: 45px;
			background-color: #e6e6e6b2;
			display: block;
		}
	}

	.values {
		display: flex;
		gap: 42.5px;

		& > div {
			.title {
				width: 121px;
				background-color: #e6e6e6b2;
				height: 24px;
			}

			.value {
				display: flex;

				.no {
					font-style: normal;
					font-weight: bold;
					font-size: 22px;
					line-height: 28px;
					letter-spacing: 0.5px;
					color: #242f57;
				}

				.percentage {
					margin-left: 6.5px;

					&.new_registration {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.5px;
						color: #04b78a;
					}

					&.donations {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.5px;
						color: #fa5087;
					}
				}
			}
		}
	}

	.header {
		h3 {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 30px;
			/* identical to box height, or 136% */

			letter-spacing: 0.5px;

			color: #242f57;
		}

		.chart {
			width: 100%;
			height: 14px;
			margin-top: 24.5px;
			background: #eaedf7;
			border-radius: 4px;

			.track {
				width: 65%;
				height: 100%;
				background-color: #e6e6e6b2 !important;
				border-radius: 4px;
			}
		}
	}

	._values {
		display: flex;
		justify-content: space-between;
		height: 150px;

		.title {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 26px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #97a0c3;
		}

		.value {
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 28px;
			text-align: center;
			letter-spacing: 0.5px;
			color: #242f57;
		}
	}

	.canvassing_report_chart {
		width: 100%;
		height: 430px;
		background-color: #e6e6e6b2;
		margin-top: 20px;
	}
}

.NewsCardSkeleton {
	position: relative;
	overflow: hidden;

	width: 100%;

	.LightWrapper {
		width: 100%;
		margin-top: 0;
		margin-bottom: 25px;
		background: #ffffff;
		border: 1px solid #eaedf7;
		box-shadow: 0px 2px 4px rgb(28 41 90 / 4%);
		border-radius: 10px;
	}

	.head {
		width: 100%;
		display: flex;
		justify-content: space-between;

		& > div {
			display: flex;
			align-items: center;
		}

		.author {
			width: 100px;
			height: 18px;
			background-color: #e6e6e6b2 !important;
			margin-bottom: 10px;
		}

		.time {
			width: 50px;
			height: 16px;
			background-color: #e6e6e6b2 !important;
		}

		.photo {
			width: 42px;
			height: 42px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e6e6e6b2 !important;

			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tag {
			width: 133px;
			background-color: #e6e6e6b2 !important;
			border-radius: 8px;
			padding: 8px 23px;
		}
	}

	.featured_image {
		margin-top: 16px;
		width: 100%;
		background-color: #e6e6e6b2 !important;
		height: 250px;
		border-radius: 10px;
	}

	.title {
		width: 200px;
		background-color: #e6e6e6b2 !important;
		height: 24px;
	}

	.text {
		width: 100%;
		height: 13px;
		background-color: #e6e6e6b2 !important;
		margin-bottom: 10px;
	}

	.reactions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div.react {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 17px;
		}

		.likes {
			display: flex;
			align-items: center;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.5px;
			color: rgba(0, 128, 255, 0.9);

			div.count {
				margin-right: 8px;
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.5px;
				color: #ffffff;
				background: #e6e6e6b2 !important;
				border-radius: 6px;
			}
		}

		.comments,
		.shares {
			width: 80px;
			background: #e6e6e6b2 !important;
			height: 16px;
		}

		.options {
			cursor: pointer;
		}
	}
	.text {
		margin-top: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.5px;
		color: #636e95;

		& > span {
			color: #369afe;
			cursor: pointer;
		}
	}
}

div.CategorySkeleton {
	position: relative;
	overflow: hidden;

	width: 100px;
	height: 38px;
	background: #e6e6e6b2 !important;
	border: 1px solid #e6e6e6b2;
	border-radius: 8px;
}
