.ProfileCard {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.options {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	.photo {
		margin-top: 14px;
		margin-bottom: 18px;
		width: 56px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #242f57;
		border-radius: 50%;

		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.5px;
		color: #ffffff;
		text-transform: uppercase;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.user {
		margin-top: 2px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.5px;
		color: #242f57;
	}

	.email {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.5px;
		color: rgba(99, 110, 149, 0.9);
	}

	.__btn {
		width: 126px;

		.OutlineButton {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin: 26px 0 0 0;
		}
	}
}
