header.Header {
	width: calc(100% - 233px);
	height: 82px;
	padding: 20px 25px;
	background: #ffffff;
	border-bottom: 1px solid #eceff8;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 9;

	.left {
		.search {
			min-width: 466px;
			display: flex;
			align-items: center;
			margin: 19px 0;

			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.5px;
			color: #636e95;

			padding: 9px 12px;
			background: #fafcfe;
			border: 1px solid #97a0c3;
			border-radius: 10px;

			svg {
				margin-right: 11px;
			}

			input {
				width: 100%;
				border: none;
				background-color: transparent;
				outline: none;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;

		.notification {
			cursor: pointer;
		}

		.profile {
			display: flex;
			align-items: center;
			margin-left: 42px;
			cursor: pointer;
			position: relative;

			&:hover {
				.options {
					display: flex;
					animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
				}
			}
			@keyframes slide-bottom {
				0% {
					-webkit-transform: translate(0%, 0);
					transform: translate(0%, 0);
				}
				100% {
					-webkit-transform: translate(0%, -5px);
					transform: translate(0%, -5px);
				}
			}

			.profile_container {
				display: flex;
				align-items: center;

				.photo {
					width: 42px;
					height: 42px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #242f57;
					border-radius: 50%;

					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 22px;
					letter-spacing: 0.5px;
					color: #ffffff;
					text-transform: uppercase;

					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}
				.user {
					margin-left: 7px;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 22px;
					letter-spacing: 0.5px;
					color: #242f57;
					text-transform: capitalize;
				}
			}

			svg {
				margin-left: 13px;
			}

			.options {
				width: 120px;
				height: 77px;
				padding: 16px 14px;
				position: absolute;
				right: 0;
				top: 2.9rem;

				display: flex;
				flex-direction: column;
				justify-content: space-between;

				background: #ffffff;
				border: 1px solid rgba(216, 216, 216, 0.3);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
				border-radius: 5px;
				display: none;

				a,
				span {
					text-decoration: none;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					/* identical to box height */

					color: #0d0c22;
				}
			}
		}
	}
}
