.User {
	width: 100%;

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 36px;
		letter-spacing: 0.5px;
		color: #242f57;
	}

	h3 {
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 0.5px;
		color: #242f57;
	}

	span {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.5px;
		color: #636e95;
	}

	.photo_details {
		display: flex;
		align-items: center;
		margin-top: 40px;

		.photo {
			width: 62px;
			height: 62px;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #242f57;
			border-radius: 50%;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #ffffff;
			text-transform: uppercase;

			span {
				color: #ffffff;
			}

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.details {
			.name {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 26px;
				letter-spacing: 0.5px;
				color: #242f57;
			}

			.email {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.5px;
				color: rgba(99, 110, 149, 0.9);
			}
		}
	}

	form {
		margin-top: 40px;
	}
}
